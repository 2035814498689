import { useEffect } from "react";
import usePageTitle from "../hooks/usePageTitle";

function ExternalRedirect({ url }) {
  useEffect(() => {
    // Redirect to the external URL
    window.location.href = url;
  }, [url]);

  usePageTitle("TV");
  return (
    <section>
      <div
        style={{
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontSize: "1.2rem", // optional styling
        }}
      >
        Redirecting ...
      </div>
    </section>
  );
}

export default ExternalRedirect;
